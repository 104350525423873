<template>
  <div
    v-if="!!project && Object.values(project).length"
    class="feasibility-details-page"
  >
    <div class="breadcrumbs-wrapper">
      <p>
        <router-link to="/feasibility-projects"
          >Active Feasibilities</router-link
        >
        <span>
          <span v-if="project.project_number"
            >/ # {{ project.project_number }}</span
          >
          <span v-if="project.feasibility_number"
            >/ # {{ project.feasibility_number }}</span
          >
        </span>
      </p>
    </div>
    <ProjectDetails :project="project" />

    <div>
      <UserSidebar v-if="project.categories" :categories="project.categories" />
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import ProjectDetails from "@/components/project/ProjectDetails.vue";
import UserSidebar from "@/components/base/UserSidebar.vue";

export default {
  components: {
    ProjectDetails,
    UserSidebar,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    onMounted(async () => {
      await store.dispatch("projects/getProject", route.params.id);
    });

    const project = computed(() => store.getters["projects/project"]);

    return { project, route };
  },
};
</script>

<style lang="scss" scoped>
.feasibility-details-page {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.lines-wrapper {
  padding: 20px 0;
}
</style>
